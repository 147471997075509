// import logo from './logo.svg';
import './App.css';
import { Tldraw } from 'tldraw'
import 'tldraw/tldraw.css'
function App() {
  return (
    <div className="App">
      <div style={{ position: 'fixed', inset: 0 }}>
			<Tldraw />
		</div>
    </div>
  );
}

export default App;
